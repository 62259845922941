<!-- 业务记录 -- 学生预选科-- 列表 -->
<template>
  <div>
    <list-template
        :loading="loading"
        :search-config="searchConfig"
        :is-check-input-box="false"
        @onSearch="onSearch"
        :table-config="tableConfig"
        :clearSort="clearSort"
        :table-data="tableData"
        :total="total"
        :current-page="page"
        @onSortChange="sortChange"
        @onChangePage="handleCurrentChange"
        @onHandle="tableHandle"
    >
      <!-- <template slot="column">
        <el-table-column label="操作" width="120">
          <template slot-scope='scope'>
            <div class="handle-box">
              <p v-if="scope.row.options === 'wait'">待提交</p>
              <a v-if="scope.row.options === 'wait-details'" href="javascript:"
                 @click="$router.push('./details?id=' + scope.row.id)">待提交</a>
              <a v-if="scope.row.options === 'approve'" href="javascript:"
                 @click="$router.push('./check?id=' + scope.row.id)">审核</a>
              <a v-if="scope.row.options === 'details'" href="javascript:"
                 @click="$router.push('./details?id=' + scope.row.id)">详情</a>
            </div>
          </template>
        </el-table-column>
      </template> -->
    </list-template>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading: true,
      search: {},
      searchConfig: [
        {
          prop: 'student_name',
          placeholder: '搜索学生姓名'
        },
        {
          prop: 'operation',
          placeholder: '搜索运营位'
        },
        {
          prop: 'wise',
          tag: "select",
          placeholder: '选科是否合理',
          options: [
            {value: 1, label: '合理'},
            {value: 2, label: '不合理'},
          ],
        },
        {
          prop: 'assemble',
          tag: "select",
          placeholder: '筛选科目组合',
          options: [],
        },
        {
          prop: 'school_id',
          tag: "select",
          placeholder: '筛选校区',
          options: [],
          label: "school_name",
          value: "id"
        },
        {
          prop: 'class_id',
          tag: "select",
          placeholder: '筛选行政班',
          options: [],
        },
        {
          prop: 'status',
          tag: "select",
          placeholder: '筛选审批状态',
          options: [],
        },
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,
      order: '',
      clearSort: false,

      tableConfig: [
        {width: "80rem", label: '序号', type: 'number'},
        {prop: 'student_name', label: '学生姓名'},
        {prop: 'student_no', label: '学号'},
        {prop: 'class_name', label: '行政班'},
        {prop: 'operation_name', label: '运营位名称'},
        {prop: 'recommend_assemble', label: '推荐科目组合'},
        {prop: 'is_same', label: '自选组合推荐组合是否一致', width: 130, sortable: 'custom'},
        {prop: 'wise', label: '选科是否合理', width: 90, sortable: 'custom'},
        {prop: 'hit', label: '选科次数', width: 80, sortable: 'custom'},
        {prop: 'school_name', label: '校区'},
        {prop: 'created_at', label: '提交时间', sortable: 'custom', width: 180},
        {
          prop:"handle",
          width:"140rem",
          label:"操作",
          handle:true,
          buttons:(row) => {
            if(row.options === 'wait-details') {
              return  [{ type:"view", text:"待提交" }]
            } else if(row.options === 'approve') {
              return  [{ type:"approve", text:"审核" }]
            } else if(row.options === 'details') {
              return  [{ type:"view", text:"查看" }]
            } else if(row.options === 'wait') {
              return '待提交'
            }
          }
        }
      ]
    }
  },
  created() {
    this.$store.commit('setPage', 1)
    this.getData()
    this.getSchool()
    this.getClass()
    this.getSubject()
    this.getStatus()
  },
  activated() {
    this.getData()
    this.getSchool()
    this.getClass()
    this.getSubject()
    this.getStatus()
  },
  computed: {
    ...mapState(['page'])
  },
  methods: {
    tableHandle(row,config){
      const { type } = config
      if(type === 'approve') {
        this.$router.push({path:"./check",query:{id:row.id}});
      } else if(type === 'view') {
        this.$router.push({path:"./details",query:{id:row.id}});
      } else if(type === 'wait-details') {
        this.$router.push({path:"./details",query:{id:row.id}});
      }
    },
    getSchool() {
      this.$_axios2('api/pre-course-select/options/schools').then(res => {
        this.searchConfig[4].options = res.data.data || []
      })
    },

    getClass() {
      this.$_axios2('api/pre-course-select/options/classes').then(res => {
        this.searchConfig[5].options = res.data.data || []
      })
    },

    getSubject() {
      this.$_axios2('api/pre-course-select/options/group').then(res => {
        this.searchConfig[3].options = res.data.data || []
      })
    },

    getStatus() {
      this.$_axios2('api/pre-course-select/options/status').then(res => {
        this.searchConfig[6].options = res.data.data || []
      })
    },

    getData() {
      this.loading = true
      let params = {...this.search, page: this.page}
      let order = this.order
      if (order) {
        params.order = order
      }
      this.tableData = []
      this.$_axios2('api/pre-course-select/pc/apply-list', {params})
          .then(res => {
            let data = res.data.data
            this.tableData = data.list
            this.total = data.page.total
          })
          .finally(() => {
            this.loading = false
          })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.order = ''
      this.clearSort = true
      this.search = val
      this.getData(val)
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit('setPage', val)
      //拉取数据
      this.getData()
    },

    sortChange({column, prop, order}) {
      let str;
      switch (prop) {
        case 'is_same':
          str = order ? (order === 'ascending' ? 'is_same-yes' : 'is_same-no') : null;
          break;
        case 'wise':
          str = order ? (order === 'ascending' ? 'wise-yes' : 'wise-no') : null;
          break;
        case 'hit':
          str = order ? (order === 'ascending' ? 'hit-desc' : 'hit-asc') : null;
          break;
        case 'created_at':
          str = order ? (order === 'ascending' ? 'created_at-desc' : 'created_at-asc') : null;
          break;
      }
      this.order = str
      this.clearSort = false
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.role-list {
  padding-top: 24rem;
}

.button {
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}

.table {
  margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}

.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}

.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}

.handle-box {
  p {
    color: rgba(0, 0, 0, .6);
  }

  a {
    text-indent: 0;
  }
}
</style>
